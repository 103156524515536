import { ApiClient } from './ApiClient';


let serverUrl = process.env.REACT_APP_API_URL;
let client = new ApiClient(serverUrl);

const JobClient =  {

    allJobs() {
        return client.get('public/find');
    },
    getJob(jobId) {
        return client.get(`public/job/${jobId}`);
    },
    findJobs(searchParams) {
        return client.get(`public/find`, { params: searchParams });
    },
    sendApplication(formData) {
        return client.post("public/upload", formData);
    },
}

export default JobClient
