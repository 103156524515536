import React, {Component} from 'react';
import {ConfigProvider} from "antd";
import MainLayout from "./page/MainLayout";
import {Router, Switch, Route, Link} from "react-router-dom";
import moment from 'moment';
import {history} from './helpers/history';

import ScrollToTop from "./helpers/ScrollToTop"

// React-Query
import {QueryClient, QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from 'react-query/devtools'

import './App.css';
import locale from "antd/es/date-picker/locale/de_DE";
const queryClient = new QueryClient();

moment.locale('de');

//import logo from './logo.svg';
//import './App.css';

function App() {
  return (
    <div className="App">
        <ConfigProvider locale={locale}>
            <Router history={history}>
                <ScrollToTop />
                <QueryClientProvider client={queryClient}>
                    <MainLayout />
                    <ReactQueryDevtools initialIsOpen={true} />
                </QueryClientProvider>

            </Router>
        </ConfigProvider>
    </div>
  );
}

export default App;
