import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {Divider, Button} from 'antd';

import {ImLocation} from "react-icons/im";
import {BiTime} from "react-icons/bi";
import moment from "moment";

import parse from "html-react-parser";
import styles from "./jobs.module.css";
import styled from "styled-components";

// History
import {history} from "../helpers/history";
import JobClient from "../network/JobClient";
import {useParams} from "react-router";

const MyDivider = styled(Divider)`
  &&& {
    font-size: 20px;
    font-weight: 600;
    border-top-color: rgba(0, 0, 0, 0.2);
  }
`;

const JobViewer = (props) => {

    const location = useLocation();
    const { id } = useParams();

    const [selectedJob, setJob] = useState(undefined);
    const [assignedOffice, setOffice] = useState(undefined);
    const [address, setAddress] = useState(undefined);

    //let selectedJob = location.state.data.job;
    //let assignedOffice = location.state.data.office;
    //let address = JSON.parse(assignedOffice.address);

    useEffect(() => {

        if(location.state !== undefined) {
            if(location.state.data.job !== undefined) {
                setJob(location.state.data.job);
            }
            if(location.state.data.office !== undefined) {
                setOffice(location.state.data.office);
                setAddress(JSON.parse(location.state.data.office.address));
            }
        }
        else {
            console.log("Job ID: ",id);
            JobClient.getJob(id)
                .then(response => {
                    const job = response.data.job;
                    setJob(job);
                    setOffice(job.office);
                    setAddress(JSON.parse(job.office.address));

                    }
                )
                .catch(error => {
                    console.log("An error while calling the API happened!")
                    console.log(error);
                    throw error;
                });
        }
        console.log("Use effect called");
        //console.log(location.state.job); // result: 'some_value'
    }, [location]);

    // Apply for Job function
    const applyForJob = () => {
        console.log("Apply for job");
        console.log( location.state.data);
        history.push("/job/apply",{data: location.state.data});
    };

    if(selectedJob === undefined || assignedOffice === undefined || address === undefined) {
        return (<div>Bitte Warten</div>)
    }

    return (



        <div className={styles['job-viewer-block']}>
            <div className={styles['job-viewer-nav']}>
               <span>
                   {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                   <a onClick={e => {
                       e.stopPropagation();
                       history.push("/");
                   }}>Zurück zur Übersicht</a>
               </span>
            </div>
            <div className={styles['job-viewer-title-block']}>
                <div className={styles['job-viewer-title']}>{selectedJob.title}</div>
                <div className={styles['job-viewer-location']}>
                    <div className={styles['job-viewer-location-city']}><ImLocation/>&nbsp;&nbsp;
                        <span>Einsatzort: {String(selectedJob.zipCode).padStart(5, '0')} {selectedJob.location}</span></div>
                    <div className={styles['job-viewer-creation-date']}><BiTime/>&nbsp;&nbsp;
                        <span>Erstellt am {moment(selectedJob.createdAt * 1000).format('DD. MMMM YYYY')}</span></div>
                </div>
            </div>


            <div className={styles['job-viewer-section-header']}>{parse(selectedJob.contentHeader)}</div>

            <div className={styles['job-viewer-section-offerings']}>
                <MyDivider className={styles['job-viewer-offerings-header']} orientation="left">Das erwartet
                    Sie</MyDivider>
                <div className={styles['job-viewer-content']}>{parse(selectedJob.contentOfferings)}</div>
            </div>

            <div className={styles['job-viewer-section-tasks']}>

                <MyDivider className={styles['job-viewer-offerings-header']} orientation="left">Das sind Ihre
                    Aufgaben</MyDivider>
                <div className={styles['job-viewer-content']}>{parse(selectedJob.contentTasks)}</div>
            </div>

            <div className={styles['job-viewer-section-demands']}>
                <MyDivider className={styles['job-viewer-offerings-header']} orientation="left">Das bringen Sie
                    mit</MyDivider>
                <div className={styles['job-viewer-content']}>{parse(selectedJob.contentDemands)}</div>
            </div>

            <div className={styles['job-viewer-section-footer']}>{parse(selectedJob.contentFooter)}</div>
            <div className={styles['job-viewer-apply-section']}>
                <div className={styles['job-viewer-apply-address']}>
                    <div>{address[0]}</div>
                    <div>{address[1]}</div>
                    <div>{address[2]}</div>

                    <div style={{"marginTop": "20px"}}>E-Mail: <a
                        href="mailto:{assignedOffice.email}">{assignedOffice.email}</a></div>
                    <div>Telefon: {assignedOffice.phone}</div>
                </div>
                <div className={styles['job-viewer-apply-button']}>
                    <Button type="primary" size="large" onClick={e => {
                        e.stopPropagation();
                        applyForJob();
                    }}>Jetzt online bewerben</Button>
                </div>
            </div>

            <div className={styles['job-viewer-nav']}>
               <span>
                   {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                   <a onClick={e => {
                       e.stopPropagation();
                       history.push("/");
                   }}>Zurück zur Übersicht</a>
               </span>
            </div>
        </div>
    )
}

export default JobViewer;
