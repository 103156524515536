import React, {useState} from 'react';

import {Button, Checkbox, Col, Form, Input, List, Row, Select} from 'antd';
import {isError, refetch, useQuery} from 'react-query';
import JobClient from "../network/JobClient";
import styles from "./jobs.module.css";
import parse from 'html-react-parser';
import Geocode from "react-geocode";

// History
import {history} from "../helpers/history";

// Icons
import {ImLocation} from 'react-icons/im';
import {BiTime} from 'react-icons/bi';

import 'moment/locale/de' // without this line it didn't work
const moment = require('moment');
moment.locale('de')

const dateFormatterOptions = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
const { Option } = Select;

Geocode.setApiKey("AIzaSyBw1fGWZuqEUMq-Wd88FczmoPE44y1NIeE");
Geocode.setLanguage("de");
Geocode.setRegion("de");

const fetchJobsWithParams = async (criteria) => {

    console.log("fetchJobsWithParams Result");
    console.log(criteria);

    return JobClient.findJobs(criteria)
        .then(response => {
        //console.log(response.data.jobCount);
        //console.log("Response Data: "+response.data);

        let newJobs = response.data.jobs.map(j => {
            const job = { ...j };
            job.key = job.id;
            job.creationDate = (new Date(job.createdAt * 1000)).toLocaleString(undefined, dateFormatterOptions);
            //console.log('Job: ' + job.title);
            return job;
        });

        //.log("New Jobs: "+newJobs);
        //console.log("New Offices: "+response.data.offices);
        return {jobs: newJobs, offices: response.data.offices};

    }).catch(error => {
            console.log("An error while calling the API happened!")
            console.log(error);
            throw error;
        });
};

const JobListing = (props) => {

    const [form] = Form.useForm();
    const [distance, setDistance] = useState("50 KM");
    const [criteria, setCriteria] = useState("");

    const { data: allData, refetch, isLoading, isSuccess, isError}
        = useQuery(["allJobs", criteria], ({ pageParam = criteria }) => fetchJobsWithParams(pageParam),
        {
                refetchOnWindowFocus: false,
                enabled: true, // turned off by default, manual refetch is needed
        });

    const onFinish = (values: any) => {
        //console.log("Form did finish");
        //console.log("Values: ", values);
        //console.log("Distance: ", distance);

        mapFormValuesToJsonObject(values).then(function(value) {
            console.log(value); //
            setCriteria(value);
            refetch();
        });
    };

    async function buildSearchObject(searchString : String) {
        return await getCoordinates(searchString);
    }

    async function mapFormValuesToJsonObject (values) {
        let result = {};

        if ( typeof values.location !== 'undefined' ) {
            if (values.location.toLowerCase() === "landau") {
                result.latitude = 48.67249;
                result.longitude = 12.69316;
            }
            else {
                let isPostalCode = validateCityOrPostalCode( values.location);

                try {
                    let response = await buildSearchObject(values.location);
                    result.latitude = response.lat;
                    result.longitude = response.lng;

                } catch (e) {
                    if (isPostalCode === true) {
                        result.zipCode = values.location;
                    }
                    else {
                        result.location = values.location;
                    }

                    console.log(e);
                }
            }


        }

        console.log("Checking title");

        if ( typeof values.title !== 'undefined' ) {
            result.title = values.title;
        }

        if ( typeof values.workingTimeModel !== 'undefined' ) {
            if(values.workingTimeModel !=="MIXED") {
                result.workingTimeModel = values.workingTimeModel;
            }
        }

        if(values.isShift === false) {
            result.isShift = false
        }

        result.distanceKm = parseInt(distance);
        return result;
    }

    function validateCityOrPostalCode(value) {
        console.log(value);
        return/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
    }

    const getCoordinates = (city : any) => {
        return new Promise((resolve, reject) => {
            Geocode.fromAddress(city).then(
                (response) => {
                    const { lat, lng } = response.results[0].geometry.location;
                    console.log(city, lat, lng);
                    resolve({ lat, lng });
                },
                (error) => {
                   reject(error);
                }
                );
        });
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
        console.log('Distance:', distanceInKm);

    };

    const onDistanceChange = value => {
        setDistance(value);
    };

    const displayJob = (jobId) => {
        console.log('The link was clicked.');
        const selectedJob = allData.jobs.find(obj => {
            return obj.id === jobId
        });

        const selectedOffice = allData.offices.find(office => {
            return office.id === selectedJob.officeId;
        });
        console.log(selectedOffice);
        console.log('selectedJob: '+selectedJob);

        const officeAndJob = {"job": selectedJob, "office":selectedOffice};
        let jobPath = "/jobs/"+jobId;
        history.push(jobPath,{data: officeAndJob});

    };

    const distanceInKm = (
        <Select defaultValue={distance} onChange={onDistanceChange} className="select-after">
            <Option value="5">5 km</Option>
            <Option value="10">10 km</Option>
            <Option value="25">25 km</Option>
            <Option value="50">50 km</Option>
            <Option value="100">100 km</Option>
        </Select>
    );

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
        },
    };

    if (isError) {
        return (
            <div>
                <p>Ein Fehler ist aufgetreten</p>
                <p>Bitte versuchen Sie es später nochmal</p>
            </div>
        )}

    else if (isLoading) { return <p>Lädt...</p>; }
    else if (!isSuccess) { return <p>Laden der Daten fehlgeschlagen.</p>; }

    return (
        <div className={styles['job-listing-block']} style={{"textAlign":"left", "margin":"1em"}}>
            <Form
                form = {form}
                layout="vertical"
                name="basic"
                {...formItemLayout}
                initialValues={
                    {
                        isShift: true,
                    }
                }
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <h1 style={{ "marginBottom": "0px"}}>Unsere Jobbörse</h1>
                <h2 style={{ "marginBottom": "50px"}}>Der passende Job ist nur einen Klick entfernt.</h2>
                <div style={{ "border": "1px solid", "borderColor": "lightgray", "padding": "20px", "margin": "0 0 30px 0px", "background": "#FDFDFD" }}>
                    <Row gutter={32} span={{xs: 24, sm: 32, md: 32, lg: 32 }}>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                label="Suchbegriff"
                                name="title"
                                rules={[{required: false, message: 'Stichwort: Jobbezeichnung oder ähnliches'}]}
                            >
                                <Input  placeholder="Stichwort: z.B. Staplerfahrer oder ähnliches"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={24} lg={8} xl={8}>
                            <Form.Item
                                label="Einsatzort"
                                name="location"
                                rules={[{required: false, message: 'Bitte den Namen des Einsatzortes eingeben'}]}
                            >
                                <Input placeholder="Ort oder Postleitzahl" addonAfter={distanceInKm}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={{ span: 4, offset: 0 }} xl={{ span:4, offset: 0 }} >
                            <Form.Item
                                label="Arbeitszeitmodel "
                                name="workingTimeModel"
                            >
                                <Select placeholder="Arbeitszeitmodel">
                                    <Option value="PART">Nur Teilzeit</Option>
                                    <Option value="FULL">Nur Vollzeit</Option>
                                    <Option value="MIXED">Teilzeit oder Vollzeit</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={{ span: 4, offset: 0 }} xl={{ span:4, offset: 0 }} >
                            <Form.Item
                                label=" "
                                name="isShift"
                                valuePropName="checked"
                            >
                                <Checkbox>Mit Schichtarbeit</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row
                          style={{"textAlign":"center"}}>
                        <Col xs={24} sm={24} md={24} lg={{ span: 8, offset: 8 }} xl={{ span:8, offset: 8 }}>
                            <Form.Item  label=" ">
                                <Button style={{"width":"100%", align: 'center'}} type="primary" htmlType="submit">
                                    <span>Nach passenden Stellen suchen</span>
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>

            </Form>

            <List
                itemLayout="vertical"
                size="medium"
                bordered= {false}
                split = {true}
                loading = {isLoading}
                pagination={{
                    onChange: page => {
                        document.body.scrollTop = 0; // For Safari
                        document.documentElement.scrollTop = 0;
                    },
                    pageSize: 10,
                }}
                dataSource={allData.jobs}
                header={
                    <div className={styles['job-listing-header']}>
                        <b>Wir haben {allData.jobs.length} Jobs für dich gefunden:</b>
                    </div>
                }
                footer={
                    <div>
                        <b>{allData.jobs.length}</b> Jobs gefunden
                    </div>
                }
                renderItem={item => (
                    <List.Item
                        key={item.id}
                    >
                        <div className={styles['job-item-block']} >

                            <div className={styles['job-item-description']}>
                                <div className={styles['job-item-title']}>{item.title}</div>
                                <div className={styles['job-item-location']}>
                                    <ImLocation/>&nbsp;&nbsp;<span>{String(item.zipCode).padStart(5, '0')} {item.location}</span>
                                    <div className={styles['job-item-creation-date']}><BiTime/>&nbsp;&nbsp;<span>Erstellt am {moment(item.createdAt*1000).format('DD. MMMM YYYY')}</span></div>
                                </div>
                                <div className={styles['job-item-content']}>{parse(item.contentHeader)}</div>
                            </div>
                            <div  className={styles['job-item-action-block']}>
                                <span>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a onClick={e => { e.stopPropagation(); displayJob(item.id) }}>Mehr erfahren</a>
                                </span>
                            </div>
                        </div>
                    </List.Item>
                )}
            />

        </div>
    )
}

export default JobListing;
