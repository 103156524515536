import React, { useState } from 'react';
import { Layout } from 'antd';

import { BrowserRouter as Router, Route, Switch} from "react-router-dom";


import JobListing from "../components/JobListing";
import JobViewer from "../components/JobViewer";
import ApplyForm from "../components/ApplyForm";

const { Content } = Layout;

function MainLayout({updateHandler}) {
    return (
        <Layout style={{ minHeight: '1vh' }}>
            <Layout style={{background: '#fff'}}>

                <Content style={{ margin: '24px 16px 0' }}>
                    <div style={{ padding: 24, background: '#fff', minHeight: 100 }}>
                            <Switch>
                                <Route exact path={["/", "/home"]} component={JobListing}/>
                                <Route exact path="/jobs/:id" component={JobViewer} />
                                <Route exact path="/job/apply" component={ApplyForm} />
                            </Switch>
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
}

export default MainLayout;