import {useState} from "react";
import {useLocation} from "react-router-dom";
import { UploadOutlined } from '@ant-design/icons';

import { Upload, Row, Col, Form, Input, Button, Modal, Checkbox, Spin } from 'antd';

import {ImLocation} from "react-icons/im";
import {GrTooltip} from "react-icons/gr";

import parse from "html-react-parser";
import styles from "./jobs.module.css";
import JobClient from "../network/JobClient";

const ApplyForm = (props) => {

    const [form] = Form.useForm();

    const [isConfirmed, setConfirmed] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [isUploading, setIsUploading] = useState(false);

    const location = useLocation();
    let selectedJob = location.state.data.job;
    let selectedOffice = location.state.data.office;

    const onFinish = (values) => {
        /*
        console.log('Success:', values);
        console.log(values);
        console.log("FileList");
        console.log(fileList);
        */

        const formData = new FormData();
        //console.log("Appending Values to Formdata");

        for (const name in values) {
            formData.append(name, values[name]); // there should be values.avatar which is a File object
        }

        if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
            formData.append('email-to', "stefan.ritter@ritter-mobile.com");
        }
        else {
            formData.append('email-to', selectedOffice.email);
        }

        formData.append('job-desc', selectedJob.title);


        if(fileList) {
            for( let i = 0; i< fileList.length ; i++){
                formData.append("documents", fileList[i].originFileObj);
            }
        }

        /*
        // Display the keys
        for (const key of formData.keys()) {
            console.log(key);
        }
        */

        setIsUploading(true);

        //console.log(process.env.REACT_APP_ENV);

        JobClient.sendApplication(formData)
            .then(res => {
                console.log("res", res);
                if (res.status === 200) {
                    console.log("Successfully finished");
                    form.resetFields();
                    Modal.success({
                        centered: true,
                        title: "Upload der Bewerbung erfolgreich",
                        content: "Ihre Bewerbung wurde erfolgreich an uns übermittelt. Nach Prüfung der Bewerbungsunterlagen werden wir mit Ihnen Kontakt aufnehmen!",
                        destroyOnClose: true
                    });
                    setConfirmed(false);
                    setIsUploading(false);
                    setFileList([]);

                }
                else {
                    Modal.error({
                        centered: true,
                        title: "Upload der Bewerbung fehlgeschlagen ("+res.status+")",
                        content: "Bei der Übermittlung der Bewerbung ist ein Fehler aufgetreten. Sollte der Fehler erneut" +
                            " auftreten, so nehmen Sie bitte per E-Mail oder Telefon mit uns Kontakt auf.",
                        destroyOnClose: true
                    });

                }
            })
            .catch(err => {
                Modal.error({
                    centered: true,
                    title: "Upload der Bewerbung fehlgeschlagen",
                    content: "Bei der Übermittlung der Bewerbung ist ein Fehler aufgetreten. Sollte der Fehler erneut" +
                        " auftreten, so nehmen Sie bitte per E-Mail oder Telefon mit uns Kontakt auf.",
                    destroyOnClose: true
                });
                console.log("err", err);
            });
    };

    const onFinishFailed = (errorInfo) => {
        //console.log('Failed:', errorInfo);
    };

    function onChangePolicy(e) {
        //console.log(`checked = ${e.target.checked}`);
        setConfirmed(e.target.checked);
    }

    const uploadProps = {
        accept: "application/pdf",
        maxCount: 3
    };

    const handleUpload = ({ fileList }) => {
        //---------------^^^^^----------------
        // this is equivalent to your "const img = event.target.files[0]"
        // here, antd is giving you an array of files, just like event.target.files
        // but the structure is a bit different that the original file
        // the original file is located at the `originFileObj` key of each of this files
        // so `event.target.files[0]` is actually fileList[0].originFileObj
        console.log('fileList', fileList);

        // you store them in state, so that you can make a http req with them later
        //this.setState({ fileList });
        setFileList(fileList);
    };

    return (
        <div className={styles['job-viewer-block']}>
            <div className={styles['job-viewer-nav']}>
               <span>
                   {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                   <a onClick={e => {
                       e.stopPropagation();
                       window.history.back();
                   }}>Zurück zur  Beschreibung</a>
               </span>
            </div>
            <div className={styles['job-viewer-title-block']}>
                <div className={styles['job-viewer-title']}>Online Bewerbung</div>
                <div className={styles['job-viewer-location']}>
                    <div className={styles['job-viewer-location-city']}><GrTooltip/>&nbsp;&nbsp;
                        <span>{selectedJob.title}</span>
                    </div>
                    <div className={styles['job-viewer-location-city']}><ImLocation/>&nbsp;&nbsp;
                        <span>Einsatzort: {selectedJob.zipCode} {selectedJob.location}</span>
                    </div>
                </div>
                <div className={styles['job-viewer-section-header']}>{parse(selectedJob.contentHeader)}</div>

            </div>

            <div className={styles['job-apply-form-container']}>
                <Form
                    form={form}
                    name="application"
                    layout={"vertical"}
                    initialValues={{
                        remember: true,
                        topic: "Online Bewerbung für die Stelle '"+selectedJob.title+"' (ID "+selectedJob.id+")",
                        policy: isConfirmed
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Row gutter={32} span={{xs: 24, sm: 24, md: 24, lg: 24 }}>
                        <Col  xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="Vorname"
                                name="firstname"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte den Vornamen eingeben',
                                    },
                                ]}
                            >
                                <Input placeholder="Dein Vorname"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="Nachname"
                                name="lastname"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte den Nachnamen eingeben',
                                    },
                                ]}
                            >
                                <Input placeholder="Dein Nachname"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={32} span={{xs: 24, sm: 24, md: 24, lg: 24 }}>
                        <Col  xs={24} sm={24} md={18} lg={8} xl={8}>
                            <Form.Item
                                label="Strasse"
                                name="street"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input placeholder="Deine Strasse"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                            <Form.Item
                                label="Hausnummer"
                                name="streetnumber"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input placeholder="Hausnummer"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                            <Form.Item
                                label="Postleitzahl"
                                name="postalCode"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input placeholder="PLZ"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={18} lg={10} xl={10}>
                            <Form.Item
                                label="Ort"
                                name="city"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input placeholder="Dein Wohnort" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={32} span={{xs: 24, sm: 24, md: 24, lg: 24 }}>
                        <Col  xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="E-Mail"
                                name="email"
                                rules={[
                                    {
                                        type: "email",
                                        message: "Bitte eine gültige E-Mail Adresse eingeben",
                                        required: true,
                                    },
                                ]}
                            >
                                <Input placeholder="Bitte E-Mail Adresse eingeben"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="Telefon"
                                name="telephone"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input placeholder="Bitte Telefon für Rückruf eingeben"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={32} span={{xs: 24, sm: 24, md: 24, lg: 24 }}>
                        <Col  xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                label="Betreff"
                                name="topic"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={32} span={{xs: 24, sm: 24, md: 24, lg: 24 }}>
                        <Col  xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                label="Nachricht"
                                name="message"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input.TextArea placeholder="Deine Nachricht an uns"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={32} span={{xs: 24, sm: 24, md: 24, lg: 24 }}>
                        <Col  xs={24} sm={24} md={24} lg={10} xl={10}>


                            <Form.Item
                                label="PDF Dateien zur Bewerbung (max. 3 Dateien)"
                                wrapperCol={{
                                    offset: 0,
                                    span: 24,
                                }}
                            >
                                <Upload {...uploadProps}
                                        fileList={fileList}
                                        onChange={handleUpload}
                                        beforeUpload={() => false}
                                >
                                    <Button icon={<UploadOutlined />}>PDF Dateien auswählen</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        name="policy"
                        onChange={onChangePolicy}
                        wrapperCol={{
                            offset: 0,
                            span: 24,
                        }}
                    >
                        <Checkbox>Ich habe die <a href="https://www.ct-personal.de/datenschutz">Datenschutzerklärung</a> gelesen und verstanden</Checkbox>
                    </Form.Item>

                    <Spin spinning={isUploading}>
                        <Form.Item
                            wrapperCol={{
                                offset: 0,
                                span: 24,
                            }}
                        >
                            {isConfirmed
                                ? <Button htmlType="submit" type="primary" >Senden</Button>
                                : <Button htmlType="submit" type="primary" disabled>Senden</Button>
                            }

                        </Form.Item>
                    </Spin>


                </Form>
            </div>
        </div>
    )
}

export default ApplyForm;